import * as actionTypes from "./actionTypes";
import http from "../../util/http";
import {
  BAND_LISTS,
  USER_ROLE_RIGHTS_ASSIGN,
  DEPARTMENT_LISTS,
  EMPLOYEE_LISTS,
  EMPLOYEE_TYPE_LISTS,
  GET_ALL_DATA_FOR_ROLE_ASSIGN,
  GET_ALL_EMPLOYEE_NAME_LIST,
  GET_ROLE_RIGHTS_LIST,
  LOCATION_LISTS,
  CLIENT_LIST,
  PROJECT_LISTS,
  SEND_BOT_NOTIFICATION,
  SET_ROLE_RIGHTS,
  UPDATE_ROLE_RIGHT,
  NOTIFICATION_EMPLOYEE_COUNT,
  BOT_NOTIFICATION_LIST,
  BOT_NOTIFICATION_EMPLOYEE_LIST,
  ORGANIZATION_LIST,
  EMPLOYEE_LIST_FROM_CLIENT,
  MODULE_LIST,
  CLIENT_ADMIN_MODULE_LIST,
  CREATE_ADMIN_FOR_CLIENT,
  UPDATE_ADMIN_FOR_CLIENT,
  GET_SUB_GROUP_FOR_CLIENT,
  GET_SUB_GROUP_FOR_CLIENT_WITHOUT_ROLE_RIGHT,
  NOTIFICATION_FILTER_LIST,
  SURVEY_CYCLE_CONFIGURATION,
  DELETE_SURVEY_CYCLE_CONFIGURATION,
  GET_SURVEY_CYCLE_CONFIGURATION,
  GET_LOCATION_LIST_FROM_SUBGROUP,
  EDIT_SURVEY_CYCLE_CONFIGURATION,
  GET_SURVEY_CYCLE_TEMPLATE_LIST,
} from "../../util/endpoints";
import { status, successMessage } from "const/constValue";
import { successToast } from "util/general";

export const getEmployeeNameListForAdmin = (name) => (dispatch) => {
  http
    .get(GET_ALL_EMPLOYEE_NAME_LIST, { name: name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let employeeList = [];
        if (response.data) {
          employeeList = response.data.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.empName} (${value.employeeId})`,
              parentGroupId: value.parentGroupId,
              subGroupId: value.subGroupId,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_EMPLOYEE_NAME_FOR_ADMIN,
          data: employeeList,
        });
      }
    })
    .catch((error) => {
      console.warn(error);
    });
};

export const getSubGroupClient = (name) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .get(GET_SUB_GROUP_FOR_CLIENT, { name: name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let subGroupClient = [];
        if (response.data) {
          dispatch({
            type: actionTypes.SET_HR_DASHBOARD_MENU_COUNT,
            data: { subGroupClient: response.data.length },
          });
          subGroupClient = response.data?.map((value) => {
            return {
              value: value.subGroupId,
              label: value.subGroupDisplayName,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_SUBGROUP_NAME,
          data: subGroupClient,
        });
      } else {
        dispatch({
          type: actionTypes.SET_SUBGROUP_NAME,
          data: [],
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_SUBGROUP_NAME,
        data: [],
      });
    });
};

export const getSubGroupClientWithoutRole = (name) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .get(GET_SUB_GROUP_FOR_CLIENT_WITHOUT_ROLE_RIGHT, { name: name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        let subGroupClient = [];
        if (response.data) {
          dispatch({
            type: actionTypes.SET_HR_DASHBOARD_MENU_COUNT,
            data: { subGroupClient: response.data.length },
          });
          subGroupClient = response.data?.map((value) => {
            return {
              value: value.subGroupId,
              label: value.subGroupDisplayName,
            };
          });
        }
        dispatch({
          type: actionTypes.SET_SUBGROUP_NAME,
          data: subGroupClient,
        });
      } else {
        dispatch({
          type: actionTypes.SET_SUBGROUP_NAME,
          data: [],
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_SUBGROUP_NAME,
        data: [],
      });
    });
};

export const getAllDataForAssignRole =
  (isEdit, subGroupId, payload) => (dispatch) => {
    let params = {
      subGroupId,
    };
    dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
    http
      .get(GET_ALL_DATA_FOR_ROLE_ASSIGN, {}, { params })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data) {
            dispatch({
              type: actionTypes.SET_DROPDOWN_DATA_FOR_ROLE,
              payload: {
                bandOption: response.data?.band?.map((v) => {
                  return { value: v, label: v };
                }),
                locationOption: response.data?.location?.map((v) => {
                  return { value: v, label: v };
                }),
                baseLocationOption: response.data?.baseLocation?.map((v) => {
                  return { value: v, label: v };
                }),
                indiaDDOption: response.data?.indiaDD?.map((v) => {
                  return {
                    value: v.employeeId,
                    label: v.empName,
                    parentGroupId: v.parentGroupId,
                    subGroupId: v.subGroupId,
                  };
                }),
                departmentOption: response.data?.department?.map((v) => {
                  return { value: v, label: v };
                }),
                designationOption: response.data?.designation?.map((v) => {
                  return { value: v, label: v };
                }),
                modulesOption: response.data?.modules,
                rightsOption: response.data?.rights?.map((v) => {
                  if (v === "VIEW") {
                    //By default VIEW disabled
                    return { value: v, label: v, disabled: true };
                  } else return { value: v, label: v };
                }),
                isLoading: isEdit ? true : false,
              },
            });
            dispatch({
              //By default VIEW selected
              type: "SET_ROLES_DATA_IN_ADMIN",
              payload: payload || {
                rights: [{ value: "VIEW", label: "VIEW" }],
                isLoading: false,
              },
            });
          }
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_DROPDOWN_DATA_FOR_ROLE,
          payload: {
            isLoading: false,
          },
        });
      });
  };

export const createRoleRights = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .post(SET_ROLE_RIGHTS, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({ type: "CLEAR_ROLES_DATA_IN_ADMIN" });
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Successfully created role",
            },
            isLoading: false,
          },
        });
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const updateRoleRights = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .put(SET_ROLE_RIGHTS, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Successfully Updated role",
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const getRoleRightsList = () => (dispatch) => {
  http
    .get(GET_ROLE_RIGHTS_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data && response.data.length > 0) {
          dispatch({ type: "CLEAR_ROLES_DATA_IN_ADMIN" });
          dispatch({
            type: actionTypes.SET_ROLE_RIGHTS_LIST,
            payload: {
              roleRightsList: response.data,
              roleOptions: response.data.map((item) => {
                return {
                  value: item.roleName,
                  label: item.roleName,
                };
              }),
              isLoading: false,
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_ROLE_RIGHTS_LIST,
            payload: {
              roleRightsList: [],
              roleOptions: [],
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_ROLE_RIGHTS_LIST,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const deleteRoleRight = (roleName) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .delete(UPDATE_ROLE_RIGHT, {}, {}, { roleName })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Role deleted successfully",
            },
          },
        });
        dispatch(getRoleRightsList());
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
        },
      });
    });
};

export const getRoleDataForEdit = (roleName) => (dispatch) => {
  http
    .get(UPDATE_ROLE_RIGHT, { roleName })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          if (response.data.subGroupId) {
            let payload = {
              role: response.data.roleName,
              band: response.data?.band?.map((v) => {
                return { value: v, label: v };
              }),
              locations: response.data?.location?.map((v) => {
                return { value: v, label: v };
              }),
              baseLocation: response.data?.baseLocation?.map((v) => {
                return { value: v, label: v };
              }),
              indiaDD: response.data?.indiaDD?.map((v) => {
                return {
                  value: v.employeeId.split("&&")[0],
                  label: v.employeeId.split("&&")[1],
                  parentGroupId: v.parentGroupId,
                  subGroupId: v.subGroupId,
                };
              }),
              departments: response.data?.department?.map((v) => {
                return { value: v, label: v };
              }),
              designations: response.data?.designation?.map((v) => {
                return { value: v, label: v };
              }),
              modules: response.data?.moduleList,
              rights: response.data?.rightsList?.map((v) => {
                return { value: v, label: v };
              }),
              subGroup: {
                value: response.data.subGroupId,
                label: response.data.subGroupName,
              },
            };
            dispatch(
              getAllDataForAssignRole(false, response.data.subGroupId, payload)
            );
          } else {
            dispatch({
              type: "SET_ROLES_DATA_IN_ADMIN",
              payload: {
                role: response.data.roleName,
                band: response.data?.band?.map((v) => {
                  return { value: v, label: v };
                }),
                locations: response.data?.location?.map((v) => {
                  return { value: v, label: v };
                }),
                baseLocation: response.data?.baseLocation?.map((v) => {
                  return { value: v, label: v };
                }),
                indiaDD: response.data?.indiaDD?.map((v) => {
                  return { value: v.split("&&")[0], label: v.split("&&")[1] };
                }),
                departments: response.data?.department?.map((v) => {
                  return { value: v, label: v };
                }),
                modules: response.data?.moduleList,
                rights: response.data?.rightsList?.map((v) => {
                  return { value: v, label: v };
                }),
              },
              loading: {
                isLoading: false,
              },
            });
          }
        }
      }
    })
    .catch(() => {
      dispatch({
        type: "SET_ROLES_DATA_IN_ADMIN",
        loading: {
          isLoading: false,
        },
      });
    });
};

export const createUserRoleRights = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .post(USER_ROLE_RIGHTS_ASSIGN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Assigned Successfully!",
            },
            isLoading: false,
          },
        });
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const updateUserRoleRights = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .put(USER_ROLE_RIGHTS_ASSIGN, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Updated Successfully!",
            },
            isLoading: false,
          },
        });
        dispatch(getUserRoleRightsList());
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message?.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const getUserRoleRightsList = () => (dispatch) => {
  http
    .get(USER_ROLE_RIGHTS_ASSIGN)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_USER_ROLES_RIGHTS_LIST,
            payload: {
              userList: response.data,
              isLoading: false,
            },
          });
        }
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_USER_ROLES_RIGHTS_LIST,
        payload: {
          isLoading: false,
        },
      });
    });
};

export const deleteUserRoleRights = (employeeIds) => (dispatch) => {
  let params = {
    employeeId: employeeIds.employeeId,
    subGroupId: employeeIds.subGroupId,
    parentGroupId: employeeIds.parentGroupId,
  };
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .delete(USER_ROLE_RIGHTS_ASSIGN, { data: params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "User Roles deleted successfully",
            },
          },
        });
        dispatch(getUserRoleRightsList());
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
        },
      });
    });
};

export const sendNotification = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .post(SEND_BOT_NOTIFICATION, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(getBotNotificationList());
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Sent Successfully!",
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const notificationEmployeeCount = (body) => (dispatch) => {
  http
    .post(NOTIFICATION_EMPLOYEE_COUNT, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data || response.data === 0) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              employeeCount: response.data,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            employeeCount: null,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          employeeCount: null,
        },
      });
    });
};

export const getNotificationFilterList = (data) => (dispatch) => {
  http
    .post(NOTIFICATION_FILTER_LIST, {}, { subGroupIds: data })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              locationOption: response.data.location.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              clientList: response.data.clientName,
              projectOption: response.data.projectIds,
              departmentOption: response.data.department.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              memberTypeOption: response.data.employeeType.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              designationOption: response.data.designation.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            locationOption: [],
            clientList: [],
            projectOption: [],
            departmentOption: [],
            memberTypeOption: [],
            designationOption: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          locationOption: [],
          clientList: [],
          projectOption: [],
          departmentOption: [],
          memberTypeOption: [],
          designationOption: [],
          isLoading: false,
        },
      });
    });
};

export const getLocationLists = () => (dispatch) => {
  http
    .get(LOCATION_LISTS)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              locationOption: response.data.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            locationOption: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          locationOption: [],
          isLoading: false,
        },
      });
    });
};

export const getClientList = () => (dispatch) => {
  http
    .get(CLIENT_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              clientList: response.data,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            clientList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          clientList: [],
          isLoading: false,
        },
      });
    });
};

export const getProjectLists = () => (dispatch) => {
  http
    .get(PROJECT_LISTS)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              projectOption: response.data,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            projectOption: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          projectOption: [],
          isLoading: false,
        },
      });
    });
};

export const getDepartmentLists = () => (dispatch) => {
  http
    .get(DEPARTMENT_LISTS)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              departmentOption: response.data.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            departmentOption: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          departmentOption: [],
          isLoading: false,
        },
      });
    });
};

export const getEmployeeLists = (name) => (dispatch) => {
  http
    .get(EMPLOYEE_LISTS, { name })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          let employeeList = response.data.map((value) => {
            return {
              value: value.employeeId,
              label: `${value.displayName} (${value.employeeId})`,
              parentGroupId: value.parentGroupId,
              subGroupId: value.subGroupId,
            };
          });
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              employeesList: employeeList,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            employeesList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          employeesList: [],
          isLoading: false,
        },
      });
    });
};

export const getEmployeeTypeLists = () => (dispatch) => {
  http
    .get(EMPLOYEE_TYPE_LISTS)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              memberTypeOption: response.data.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            memberTypeOption: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          memberTypeOption: [],
          isLoading: false,
        },
      });
    });
};

export const getBandLists = () => (dispatch) => {
  http
    .get(BAND_LISTS)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
            payload: {
              bandOption: response.data.map((v) => {
                return {
                  label: v,
                  value: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
          payload: {
            bandOption: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_LIST_OPTION_FOR_NOTIFICATION,
        payload: {
          bandOption: [],
          isLoading: false,
        },
      });
    });
};

export const getBotNotificationList = () => (dispatch) => {
  http
    .get(BOT_NOTIFICATION_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_BOT_NOTIFICATION_LIST,
            payload: {
              botNotificationList: response.data,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_BOT_NOTIFICATION_LIST,
          payload: {
            botNotificationList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_BOT_NOTIFICATION_LIST,
        payload: {
          botNotificationList: [],
          isLoading: false,
        },
      });
    });
};

export const getBotNotificationEmployeeList =
  (notificationBroadcastId) => (dispatch) => {
    dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
    http
      .get(BOT_NOTIFICATION_EMPLOYEE_LIST, { notificationBroadcastId })
      .then((response) => response.data)
      .then((response) => {
        if (response.status === status.SUCCESS) {
          if (response.data) {
            if (
              response.data.notifiedEmployees === null &&
              response.data.unnotifiedEmployees === null
            ) {
              successToast("Please wait! Notification sending is in-progress");
            }
            let notifiedEmployees =
              response.data.notifiedEmployees.length > 0
                ? response.data.notifiedEmployees.map((v) => {
                    return {
                      ...v,
                      status: status.NOTIFIED,
                    };
                  })
                : [];
            let unNotifiedEmployees =
              response.data.unnotifiedEmployees.length > 0
                ? response.data.unnotifiedEmployees.map((v) => {
                    return {
                      ...v,
                      status: status.FAILED,
                    };
                  })
                : [];
            let employees = notifiedEmployees.concat(unNotifiedEmployees);
            dispatch({
              type: actionTypes.SET_BOT_NOTIFICATION_LIST,
              payload: {
                notificationEmployeeList: employees,
                isLoading: false,
                previewPopup: true,
              },
            });
          }
        } else {
          dispatch({
            type: actionTypes.SET_BOT_NOTIFICATION_LIST,
            payload: {
              notificationEmployeeList: [],
              isLoading: false,
            },
          });
        }
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_BOT_NOTIFICATION_LIST,
          payload: {
            notificationEmployeeList: [],
            isLoading: false,
          },
        });
      });
  };

export const getOrganizationList = () => (dispatch) => {
  http
    .get(ORGANIZATION_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_SUPER_ADMIN_DATA,
            payload: {
              organizationList: response.data.map((v) => {
                return {
                  value: v.clientId,
                  label: v.clientName,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_SUPER_ADMIN_DATA,
          payload: {
            organizationList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_SUPER_ADMIN_DATA,
        payload: {
          organizationList: [],
          isLoading: false,
        },
      });
    });
};

export const getEmployeeNameFromClient = (name, parentGroupId) => {
  return http
    .get(EMPLOYEE_LIST_FROM_CLIENT, { name, parentGroupId })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          return response.data.map((value) => {
            return {
              value: value.employeeId + "&&" + value.subGroupId,
              label: value.empName,
            };
          });
        }
      }
    });
};

export const getModuleList = () => (dispatch) => {
  http
    .get(MODULE_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_SUPER_ADMIN_DATA,
            payload: {
              moduleList: response.data.map((v) => {
                return {
                  value: v,
                  label: v,
                };
              }),
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_SUPER_ADMIN_DATA,
          payload: {
            moduleList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_SUPER_ADMIN_DATA,
        payload: {
          moduleList: [],
          isLoading: false,
        },
      });
    });
};

export const getClientAdminModuleList = () => (dispatch) => {
  http
    .get(CLIENT_ADMIN_MODULE_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data) {
          dispatch({
            type: actionTypes.SET_SUPER_ADMIN_DATA,
            payload: {
              clientAdminModuleList: response.data,
              isLoading: false,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_SUPER_ADMIN_DATA,
          payload: {
            clientAdminModuleList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: actionTypes.SET_SUPER_ADMIN_DATA,
        payload: {
          clientAdminModuleList: [],
          isLoading: false,
        },
      });
    });
};

export const createAdminForClient = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .post(CREATE_ADMIN_FOR_CLIENT, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Successfully Created",
            },
            isLoading: false,
          },
        });
        dispatch(getClientAdminModuleList());
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const updateAdminForClient = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .put(UPDATE_ADMIN_FOR_CLIENT, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(getClientAdminModuleList());
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Updated Successfully!",
            },
            isLoading: false,
          },
        });
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const getLocationListFromSubGroup = (subGroupId) => (dispatch) => {
  let params = { subGroupId };
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .get(GET_LOCATION_LIST_FROM_SUBGROUP, {}, { params })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        if (response.data?.length > 0) {
          dispatch({
            type: actionTypes.SET_SURVEY_CONFIG_DATA,
            payload: {
              locationList: response.data.map((location) => {
                return {
                  label: location,
                  value: location,
                };
              }),
            },
          });
        } else {
          dispatch({
            type: actionTypes.SET_SURVEY_CONFIG_DATA,
            payload: {
              locationList: [],
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.SET_SURVEY_CONFIG_DATA,
          payload: {
            locationList: [],
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const getSurveyCycleConfig = () => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .get(GET_SURVEY_CYCLE_CONFIGURATION)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_SURVEY_CONFIG_DATA,
          payload: {
            surveyConfigList: response.data,
            isLoading: false,
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_SURVEY_CONFIG_DATA,
          payload: {
            surveyConfigList: [],
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const createSurveyCycleConfig = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .post(SURVEY_CYCLE_CONFIGURATION, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(getSurveyCycleConfig());
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Created Successfully!",
            },
          },
        });
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const updateSurveyCycleConfig = (body) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .put(EDIT_SURVEY_CYCLE_CONFIGURATION, {}, body)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(getSurveyCycleConfig());
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: "Updated Successfully!",
            },
          },
        });
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const deleteSurveyCycleConfig = (data) => (dispatch) => {
  dispatch({ type: "SET_ROLE_ASSIGN_LOADING" });
  http
    .delete(DELETE_SURVEY_CYCLE_CONFIGURATION, { data: data })
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch(getSurveyCycleConfig());
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: true,
              message: successMessage.DELETE_SUCCESSFULLY,
            },
          },
        });
      } else {
        dispatch({
          type: "ROLE_ASSIGN_RESPONSE",
          payload: {
            apiResponse: {
              success: false,
              message: response.message.message,
            },
            isLoading: false,
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};

export const getSurveyCycleTemplate = () => (dispatch) => {
  http
    .get(GET_SURVEY_CYCLE_TEMPLATE_LIST)
    .then((response) => response.data)
    .then((response) => {
      if (response.status === status.SUCCESS) {
        dispatch({
          type: actionTypes.SET_SURVEY_CONFIG_DATA,
          payload: {
            surveyCycleTemplateList: response.data,
          },
        });
      } else {
        dispatch({
          type: actionTypes.SET_SURVEY_CONFIG_DATA,
          payload: {
            surveyCycleTemplateList: [],
          },
        });
      }
    })
    .catch(() => {
      dispatch({
        type: "ROLE_ASSIGN_RESPONSE",
        payload: {
          apiResponse: {
            success: false,
            message: "Something wrong!",
          },
          isLoading: false,
        },
      });
    });
};
