import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import NoDataFound from "../NodataFound/NoDataFound";
import EmployeeListRow from "./EmployeeListRow";
import style from "./EmployeeListPopup.module.scss";
import { filterArray } from "../../util/general";
import FilterTeam from "../FilterTeam/FilterTeam";
import { noData } from "const/constValue";

function EmployeeListModalWithFilter({
  employees,
  openPopup,
  closePopup,
  modalHeading,
  modalDescription,
  secondColum,
  thirdColum,
  departmentShow,
  handleEmployeeClick,
  handleSave,
  saveBtn,
  searchPlaceholder,
  modalSize,
  filter,
  defaultStatusValue,
}) {
  let [employee, setEmployee] = useState(employees);
  const [statusFilter, setStatusFilter] = useState(null);
  let [searchFilter, setSearchFilter] = useState("");

  useEffect(() => {
    setEmployee(employees?.filter((v) => v.status === defaultStatusValue));
    return () => {
      setEmployee([]);
    };
  }, [employees]);

  const search = (e) => {
    let filteredData = null;
    const filters = {
      empName: e.target.value,
      status: statusFilter ? statusFilter : defaultStatusValue,
    };
    filteredData = filterArray(employees, filters);
    setEmployee(filteredData);
    setSearchFilter(e.target.value);
  };

  const resetSearch = () => {
    let filteredData = null;
    const filters = {
      empName: "",
      status: statusFilter ? statusFilter : defaultStatusValue,
    };
    filteredData = filterArray(employees, filters);
    setEmployee(filteredData);
    setSearchFilter("");
  };

  const handleStatusChange = (value) => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      status: value,
    };
    filteredData = filterArray(employees, filters);
    setStatusFilter(value);
    setEmployee(filteredData);
  };

  const resetData = () => {
    let filteredData = null;
    const filters = {
      empName: searchFilter ? searchFilter : "",
      status: statusFilter ? statusFilter : defaultStatusValue,
    };
    filteredData = filterArray(employees, filters);

    setEmployee(filteredData);
    setStatusFilter(statusFilter ? statusFilter : "");
    setSearchFilter(searchFilter);
  };

  return (
    <>
      <Modal
        size={modalSize}
        show={openPopup}
        onHide={closePopup}
        animation={false}
        className={style.assigneepopup_wrapper}
      >
        <div className={style.modal_header}>
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12">
              <h1>{modalHeading}</h1>
              <h2>{modalDescription}</h2>
            </div>
          </div>
        </div>
        <FilterTeam
          placeholder={searchPlaceholder}
          filter={filter}
          filterValue={defaultStatusValue}
          value={searchFilter ? searchFilter : ""}
          teamData={employee}
          onSearch={(e) => search(e)}
          onRadioChange={(e) => handleStatusChange(e.target.value)}
          onReset={resetData}
          onResetSearch={resetSearch}
        />
        <Modal.Body>
          <div className="box-light-gradient"></div>
          <div className="col-xl-12">
            <div className={style.data_container}>
              {employee && employee.length > 0 ? (
                <table>
                  <thead>
                    <tr>
                      <td className={style.name}>Name</td>
                      {secondColum && <td className={style.objective_assigned}>{secondColum}</td>}
                      {thirdColum && <td className={style.status}>{thirdColum}</td>}
                    </tr>
                  </thead>
                  <tbody>
                    {employee.map((value, index) => (
                      <React.Fragment key={value.employeeId}>
                        <EmployeeListRow
                          index={index}
                          displayPicture={value.displayPicture || value.empInitial}
                          secondColum={secondColum && value[secondColum.toLowerCase()]}
                          thirdColum={thirdColum && (value[thirdColum.toLowerCase()] || "-")}
                          department={departmentShow && value.department}
                          designation={departmentShow && value.designation}
                          empName={value.empName}
                          employeeId={value.employeeId}
                          handleEmployeeClick={handleEmployeeClick}
                          id={value.id}
                        />
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              ) : (
                <NoDataFound description={noData.WE_DONT_HAVE_THE_DATA} />
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={style.assigned_modal_footer}>
          {saveBtn && (
            <Button
              type="button"
              variant={"primary"}
              className={["btn-round", style.btn_save].join(" ")}
              onClick={handleSave}
            >
              <strong>{saveBtn}</strong>
            </Button>
          )}
          <Button
            type="button"
            variant={"secondary"}
            className={["btn-round", style.btn_cancel].join(" ")}
            onClick={closePopup}
          >
            <strong>Close</strong>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeListModalWithFilter;
EmployeeListModalWithFilter.defaultProps = {
  modalSize: "lg",
  isExpandVisible: false,
};
