import React from "react";
import { IC_CHAT_BUBBLE } from "../../../const/imgCost";
import { ReactSVG } from "react-svg";
import PropTypes from "prop-types";
import Comment from "../../CommentBox/Comment";
import { backGroundColor } from "../../../const/constValue";

export default function OneOnOneCommentAnswer(props) {
  const { addOneOnOneWith, talkingPoints, createdDate } = props?.comment;
  return (
    <div className="view_comment_box_wrapper one_on_1_comment_box">
      <div className="view_comment_box_overview justify-content-between">
        <div className="d-flex">
          <div className="view_comment_box_overview_icon">
            <ReactSVG className="svg" src={IC_CHAT_BUBBLE} alt="" />
          </div>
          <div className="view_comment_box_heading">
            <strong className="semi-bold">Added 1-on-1 with {addOneOnOneWith.empName}</strong>
          </div>
        </div>
      </div>
      <Comment
        selectedColor={backGroundColor[1]}
        image={talkingPoints.addedBy && talkingPoints.addedBy.displayPicture}
        empName={talkingPoints.addedBy && talkingPoints.addedBy.empName}
        commentedOn={createdDate}
        comment={talkingPoints.agenda}
        isPrivate={false}
        deletable={false}
      />
    </div>
  );
}

OneOnOneCommentAnswer.propTypes = {
  comment: PropTypes.shape({
    addOneOnOneWith: PropTypes.shape({
      employeeId: PropTypes.string,
      empName: PropTypes.string,
      dispArr: PropTypes.string,
      department: PropTypes.string,
    }).isRequired,
    talkingPoints: PropTypes.shape({
      surveyCycleId: PropTypes.string,
      agenda: PropTypes.string,
      addedBy: PropTypes.shape({
        employeeId: PropTypes.string,
        empName: PropTypes.string,
        dispArr: PropTypes.string,
        department: PropTypes.string,
      }),
    }).isRequired,
  }),
};
